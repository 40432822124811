import React from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby-link";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ArchiveModalList from "../components/modal/archive-modal-list";
import Headline from "../components/text-elements/headline";

const ArchivePage = (props) => {
  const setting = props.data.takeshape.getSetting;
  const archive = props.data.takeshape.getArchive;
  if (
    (!archive.enabled && process.env.GATSBY_ENVIRONMENT !== "staging") ||
    (!archive.enableOnStaging && process.env.GATSBY_ENVIRONMENT === "staging")
  ) {
    if (typeof window !== "undefined") {
      navigate("/");
    }
  }
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "archive-page footer-offset alt-header",
        }}
      />
      <Layout headerClass="header--dark">
        <Seo title={archive.pageTitle} description={setting.seoContent} />
        <Headline headline={archive.pageTitle} description={archive.title} />
        <ArchiveModalList archives={archive.archives} />
      </Layout>
    </>
  );
};

export default ArchivePage;

export const query = graphql`
  query {
    takeshape {
      getSetting {
        seoContent
      }
      getArchive {
        archives {
          children {
            children {
              children {
                content {
                  description
                  images {
                    asset {
                      path
                      title
                    }
                    title
                    width
                  }
                  title
                }
                description
                image {
                  path
                  title
                }
                title
              }
              isHorizontal
              isVertical
            }
            isHorizontal
            isVertical
          }
        }
        enabled
        enableOnStaging
        pageTitle
        title
      }
    }
  }
`;
