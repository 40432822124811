import React from "react";
import Modal from "react-modal";
import { ModalProvider, ModalConsumer } from "./modal-context";
import ModalRoot from "./modal-root";
import Headline from "../text-elements/headline";
import ArchiveModalItem from "./archive-modal-item";
import { getImageUrl } from "takeshape-routing";

const ArchiveModalList = ({archives}) => {
  const ModalComp = ({ onRequestClose, ...otherProps }) => {
    const content = otherProps.content
    return (
      <Modal isOpen onRequestClose={onRequestClose} {...otherProps}>
        <div className="modal-body">
          <div className="container container--light-bordered">
            <div className="modal-caption">
              <Headline
                headline={content.title}
                description={content.description}
              />
              <span className="modal-toggler" onClick={onRequestClose} role="presentation"/>
            </div>
            <div className="modal-content section section-columns">
              <div className="section-columns">
                <div className="row">
                  {content.images.map((image, idx) =>
                    <div className={`row__column width-${image.width}`} key={idx}>
                      <div className="box">
                        <img
                          src={getImageUrl(image.asset.path, {auto:"compress", fit:"crop"})}
                          alt={image.asset.title || ''}
                          title={image.title}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const ArchiveItem = ({archive, showModal}) => {
    return (
      <div className="modal modal-head">
        <div
          className="modal-content"
          onClick={() => showModal(ModalComp,{content: archive.content})}
          role="presentation"
        >
          <div className="layer-wrap">
            <div className="content">
              <h2>{archive.title}</h2>
              <p className="h1">{archive.description}</p>
            </div>
          </div>
          <img
            src={getImageUrl(archive.image.path, {auto:"compress", fit:"crop"})}
            alt={archive.image.title || ''}
            itemProp="contentUrl"
          />
        </div>
      </div>
    )
  }

  return (
    <ModalProvider>
      <ModalRoot />
      <ModalConsumer>
        {({ showModal }) => (
          <>
            <ArchiveModalItem>
              {archives.map((archive, idx) =>
                <div className={`row width-100 ${idx > 2 && "fade"}`} key={idx}>
                  {archive.children.map((child, idx) =>
                    <div className="row__column--no-padding width-50" key={idx}>
                      {child.isHorizontal && <div className="row">
                        {child.children.map((child, idx) =>
                          <div className="row__column--no-padding width-50" key={idx}>
                            {child.children.map((child, idx) =>
                              <ArchiveItem archive={child} key={idx} showModal={showModal} />
                            )}
                          </div>
                        )}
                      </div>}
                      {child.isVertical && child.children.map((child, idx) =>
                        <div className="row" key={idx}>
                          {child.children.map((child1, idx) =>
                            <div className={`row__column--no-padding width-${child.isHorizontal ? 50 : 100 }`} key={idx}>
                              <ArchiveItem archive={child1} key={idx} showModal={showModal} />
                            </div>)}
                          </div>
                        )}
                      {!child.isVertical && !child.isHorizontal &&
                        <ArchiveItem archive={child.children[0].children[0]} key={idx}  showModal={showModal}/>
                      }
                    </div>
                  )}
                </div>
              )}
            </ArchiveModalItem>
          </>
        )}
      </ModalConsumer>
    </ModalProvider>
  );
};

Modal.setAppElement("#___gatsby");

export default ArchiveModalList;
