import React, { Component } from "react";
import PropTypes from "prop-types";

class ArchiveModalItem extends Component {
  handleOpenCloseAccordion = () => {
    if (this.state.open) {
      this.setState({
        open: false,
        loadMoreClass: ""
      });
    } else {
      this.setState({
        open: true,
        loadMoreClass: "is--opened"
      });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loadMoreClass: ""
    };
  }

  render() {
    const { loadMoreClass } = this.state;

    const { children } = this.props;

    return (
      <>
        <div
          className={`archive-section section section-columns ${loadMoreClass}`}
        >
          <div className="container container--bordered">
            <div className="row">{children}</div>
            <div
              className="load-more-toggler"
              onClick={this.handleOpenCloseAccordion}
              role="presentation"
            >
              <span>Load More</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ArchiveModalItem.propTypes = {
  children: PropTypes.node.isRequired
};

export default ArchiveModalItem;
